/*
 *  WARNING: loading either @elastic apm modules causes serious issues for testing as it replaces the fetch mock
 *           with a real function. A mock of this module in jest-setup.ts ensures that fetch remains a mock.
 *
 * If you update this file, you should also update the factory function in the jest-setup.ts file.
 * */

import { ApmBase, init } from "@elastic/apm-rum";
import { withTransaction } from "@elastic/apm-rum-react";

import { ConfigValues, IgnoreTransactionsConfiguration } from "./configLoader/configValues";

export { withTransaction };

export let apm: ApmBase | undefined;

export function initApm(
	config: Pick<
		ConfigValues,
		"elasticApmServerUrl" | "version" | "clientId" | "elasticApmIgnoreTransactions"
	>
) {
	if (apm !== undefined) {
		return;
	}
	if (config.elasticApmServerUrl) {
		apm = init({
			serviceName: "tsunami",
			serverUrl: config.elasticApmServerUrl,
			environment: config.clientId ?? "local",
			serviceVersion: config.version,
			ignoreTransactions: parseIgnoreTransactions(config.elasticApmIgnoreTransactions)
		});
	}
}

function parseIgnoreTransactions(
	elastiApmIgnoreTransactions: IgnoreTransactionsConfiguration | undefined
): Array<string | RegExp> {
	if (!elastiApmIgnoreTransactions) {
		return [];
	}

	const ignoredTransactions: Array<string | RegExp> = [];
	for (const v of elastiApmIgnoreTransactions) {
		try {
			switch (v.type) {
				case "regex":
					// tslint:disable-next-line:tsr-detect-non-literal-regexp
					ignoredTransactions.push(new RegExp(v.value));
					break;
				case "string":
					ignoredTransactions.push(v.value);
					break;
				default:
					// can't use loggingService due to dependency clashing
					console.warn("Metrics invalid ignored transaction type", v);
					break;
			}
		} catch (e) {
			// can't use loggingService due to dependency clashing
			console.warn(e.message, v);
		}
	}
	return ignoredTransactions;
}

/**
 * Invokes and measures the given action in a new span in the current transaction. If no Apm transaction is active, a
 * new one will be created.
 * @param spanName The name of the span. If a new transaction is created, also used as the transaction name.
 * @param spanType The type of the span. If a new transaction is created, also used as the transaction type.
 * @param action The action to measure.
 */
export function measure<T>(spanName: string, spanType: string, action: () => T): T {
	const tran =
		apm?.getCurrentTransaction() ??
		apm?.startTransaction(spanName, spanType, { canReuse: true, managed: true });
	const span = tran?.startSpan(spanName, spanType);
	try {
		return action();
	} finally {
		span?.end();
		tran?.end();
	}
}

/**
 * Creates a new function whose execution will be measured in a new span in the current transaction whenever it is
 * called. If no Apm transaction is active when the function is invoked, a new one will be created.
 * @param spanName The name of the span. If a new transaction is created, also used as the transaction name.
 * @param spanType The type of the span. If a new transaction is created, also used as the transaction type.
 * @param anyFunc The function to measure.
 */
export function createMeasuredFunction<T extends Array<any>, U>(
	spanName: string,
	spanType: string,
	anyFunc: (...args: T) => U
): (...args: T) => U {
	return (...args: T) => measure(spanName, spanType, () => anyFunc(...args));
}

// keep in sync with jest-setup mock
export enum TransactionType {
	Component = "component",
	Custom = "custom"
}

// keep in sync with jest-setup mock
export enum SpanType {
	Function = "function",
	Selector = "selector"
}
