import { ConfigValues } from "./configValues";

/**
 * Fetch the tenancy configuration data via a request
 * or return the embedded window data
 *
 * @class FetchConfig
 */
class FetchConfig {
	static async getData() {
		// fallback for local embedded/cached data
		if (typeof window === "undefined") return {} as any;
		if ((window as any).config) return (window as any).config;

		// otherwise make a request
		try {
			const response = await fetch("/api/tsunami");
			const data: ConfigValues = await response.json();

			// we should aim to remove this window object
			// added for backwards compat
			(window as any).config = data;
			return data;
		} catch (err) {
			throw new Error(err);
		}
	}
}

export default FetchConfig.getData();
