/**
 * window, or jsdom in test env
 */
import device from "current-device";

export const windowV: Window = getWindow(global ? (global as any).window : window);
export const document: Document = windowV.document;

export const isMobile: boolean = device.mobile();
export const isMobileOrTablet: boolean = isMobile || device.tablet();
export const isIe11: boolean = /rv:11.0/i.test(windowV.navigator.userAgent);
export const isEdge: boolean = /Edge/g.test(windowV.navigator.userAgent);
export const isWindowsBrowser: boolean = isIe11 || isEdge;
export const isMac: boolean = (device as any).macos(); // MacOs type has to be added: https://github.com/matthewhudson/current-device/pull/274

function getWindow(window: Window): Window {
	if (typeof window === "undefined") {
		return window;
	}
	if (global && (global as any).JEST_ACTIVE) {
		(window as any).navigator.__defineGetter__(
			"userAgent",
			() => "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.13; rv:64.0) Gecko/20100101 Firefox/64.0"
		);

		(window as any).configValues = {
			defaultCurrency: "GBP"
		};
	}
	return window;
}
