import { initApm } from "./core/lib/metrics";
import loadConfig from "./core/lib/configLoader/configLoader";

// import master styles
import "../style.scss";
import { isIe11 } from "./sdk/helpers/browser";

// This is required due to ReactTooltip bug on IE11. Please remove when
// the problem is solved (https://github.com/wwayne/react-tooltip/issues/673)
if (isIe11) {
	// @ts-ignore
	document.constructor.__defineGetter__("name", function () {
		// @ts-ignore
		return this.toString()
			.trim()
			.replace(/^\S+\s+(\w+)[\S\s]+$/, "$1");
	});
}

// load the tenancy configuration then boot the app as an async request
loadConfig.then((configValues) => {
	initApm(configValues);
	import("./app");
});
